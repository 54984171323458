<template>
  <div>
    <v-row>
      <v-col cols="3" v-if="!isCorporateHide">
        <validation-provider
          :vid="namePrefix + isCorporateName"
          :name="
            isCorporateLabel != null ? isCorporateLabel : $t('is corporate')
          "
          :rules="isCorporateRules != null ? isCorporateRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-switch
            v-model="isCorporateValue"
            :name="namePrefix + isCorporateName"
            :label="
              isCorporateLabel != null ? isCorporateLabel : $t('is corporate')
            "
            :hint="isCorporateHint != null ? isCorporateHint : null"
            :disabled="disabled || isCorporateDisabled"
            :error-messages="errors"
            :class="classes"
            :clearable="!isMobile"
          />
        </validation-provider>
      </v-col>
      <v-col
        :cols="isCorporateHide ? 12 : 9"
        v-if="(isCorporateValue && !companyHide) || companyForceShow"
      >
        <validation-provider
          :vid="namePrefix + companyName"
          :name="companyLabel != null ? companyLabel : $t('company name')"
          :rules="companyRules != null ? companyRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="companyValue"
            :name="namePrefix + companyName"
            :label="companyLabel != null ? companyLabel : $t('company name')"
            :hint="companyHint != null ? companyHint : null"
            :disabled="disabled || companyDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="companyMaxLength != null ? companyMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="!(salutationHide && firstNameHide && lastNameHide)">
      <v-col cols="2" v-if="!salutationHide">
        <salutation-input
          v-model="salutationValue"
          :name="salutationName"
          :label="salutationLabel != null ? salutationLabel : $t('salutation')"
          :rules="salutationRules"
          :disabled="disabled || salutationDisabled"
          :name-prefix="namePrefix"
          :no-none="companyHide"
        />
      </v-col>
      <v-col :cols="salutationHide ? 6 : 5">
        <validation-provider
          v-if="!firstNameHide"
          :vid="namePrefix + firstNameName"
          :name="firstNameLabel != null ? firstNameLabel : $t('first name')"
          :rules="firstNameRules != null ? firstNameRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="firstNameValue"
            :name="namePrefix + firstNameName"
            :label="firstNameLabel != null ? firstNameLabel : $t('first name')"
            :hint="firstNameHint != null ? firstNameHint : null"
            :disabled="disabled || firstNameDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="firstNameMaxLength != null ? firstNameMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
      <v-col :cols="salutationHide ? 6 : 5">
        <validation-provider
          v-if="!lastNameHide"
          :vid="namePrefix + lastNameName"
          :name="lastNameLabel != null ? lastNameLabel : $t('last name')"
          :rules="lastNameRules != null ? lastNameRules : ''"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="lastNameValue"
            :name="namePrefix + lastNameName"
            :label="lastNameLabel != null ? lastNameLabel : $t('last name')"
            :hint="lastNameHint != null ? lastNameHint : null"
            :disabled="disabled || lastNameDisabled"
            :error-messages="errors"
            :success="dirty && valid"
            :maxlength="lastNameMaxLength != null ? lastNameMaxLength : null"
            :class="classes"
            :clearable="!isMobile"
            v-disabled-icon-focus
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SalutationInput from "@/components/basics/SalutationInput";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "AddressNames",
  components: {
    SalutationInput,
  },
  props: {
    value: {},
    isCorporateName: {
      type: String,
      default: "is_corporate",
    },
    isCorporateLabel: {
      type: String,
      default: null,
    },
    isCorporateHint: {
      type: String,
      default: null,
    },
    isCorporateRules: {
      type: String,
      default: null,
    },
    isCorporateDisabled: {
      type: Boolean,
      default: false,
    },
    isCorporateHide: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
      default: "company",
    },
    companyLabel: {
      type: String,
      default: null,
    },
    companyHint: {
      type: String,
      default: null,
    },
    companyRules: {
      type: String,
      default: null,
    },
    companyMaxLength: {
      type: [Number, String],
      default: null,
    },
    companyDisabled: {
      type: Boolean,
      default: false,
    },
    companyHide: {
      type: Boolean,
      default: false,
    },
    companyForceShow: {
      type: Boolean,
      default: false,
    },
    salutationName: {
      type: String,
      default: "salutation",
    },
    salutationLabel: {
      type: String,
      default: null,
    },
    salutationHint: {
      type: String,
      default: null,
    },
    salutationRules: {
      type: String,
      default: null,
    },
    salutationDisabled: {
      type: Boolean,
      default: false,
    },
    salutationHide: {
      type: Boolean,
      default: false,
    },
    firstNameName: {
      type: String,
      default: "first_name",
    },
    firstNameLabel: {
      type: String,
      default: null,
    },
    firstNameHint: {
      type: String,
      default: null,
    },
    firstNameRules: {
      type: String,
      default: null,
    },
    firstNameMaxLength: {
      type: [Number, String],
      default: null,
    },
    firstNameDisabled: {
      type: Boolean,
      default: false,
    },
    firstNameHide: {
      type: Boolean,
      default: false,
    },
    lastNameName: {
      type: String,
      default: "last_name",
    },
    lastNameLabel: {
      type: String,
      default: null,
    },
    lastNameHint: {
      type: String,
      default: null,
    },
    lastNameRules: {
      type: String,
      default: null,
    },
    lastNameMaxLength: {
      type: [Number, String],
      default: null,
    },
    lastNameDisabled: {
      type: Boolean,
      default: false,
    },
    lastNameHide: {
      type: Boolean,
      default: false,
    },
    namePrefix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isMobile],
  data: () => ({
    isCorporateValue: null,
    companyValue: null,
    salutationValue: null,
    firstNameValue: null,
    lastNameValue: null,
  }),
  watch: {
    isCorporateValue() {
      this.$emit("input", this.getValue());
    },
    companyValue() {
      this.$emit("input", this.getValue());
    },
    salutationValue() {
      this.$emit("input", this.getValue());
    },
    firstNameValue() {
      this.$emit("input", this.getValue());
    },
    lastNameValue() {
      this.$emit("input", this.getValue());
    },
    value: {
      immediate: true,
      handler(value) {
        this.isCorporateValue =
          this.value && this.value[this.isCorporateName] != null
            ? this.value[this.isCorporateName]
            : this.value &&
              this.value[this.companyName] != null &&
              this.value[this.companyName] != ""
            ? true
            : false;
        this.companyValue =
          this.value && this.value[this.companyName] != null
            ? this.value[this.companyName]
            : "";
        this.salutationValue =
          this.value && this.value[this.salutationName] != null
            ? this.value[this.salutationName]
            : "";
        this.firstNameValue =
          this.value && this.value[this.firstNameName] != null
            ? this.value[this.firstNameName]
            : "";
        this.lastNameValue =
          this.value && this.value[this.lastNameName] != null
            ? this.value[this.lastNameName]
            : "";
      },
    },
  },
  methods: {
    getValue() {
      return Object.assign(this.value, {
        [this.isCorporateName]: this.isCorporateValue,
        [this.companyName]: this.companyValue,
        [this.salutationName]: this.salutationValue,
        [this.firstNameName]: this.firstNameValue,
        [this.lastNameName]: this.lastNameValue,
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "is corporate": "Is corporate",
    "company name": "Company name",
    "salutation": "Salutation",
    "first name": "First name",
    "last name": "Last name"
  }, 
  "de": {
    "is corporate": "Ist Firma",
    "company name": "Firmenname",
    "salutation": "Anrede",
    "first name": "Vorname",
    "last name": "Nachname"
  }
}
</i18n>
